button.dates {
    border: none;
    padding: 0.5em;
    cursor: pointer;
    display: inline-block;
    background: mediumseagreen;
    color: white;
    font-weight: bold;
    font-size: 16px;
    margin: 1em;
    height: 37px;
}

.link {
    cursor: pointer;
    color: dodgerblue;
}

.link:hover {
    color: blue;
}

.react-calendar.stonk {
    width: 500px;
}

.stonk .react-calendar__tile {
    width: 6em;
    height: 5em;
}

.react-calendar__tile.green {
    background: palegreen;
}
.react-calendar__tile.red {
    background: pink;
}

.stonk .react-calendar__tile--active {
    background: #006edc;
}

.sortable {
    cursor: pointer;
}

.sortable .up,
.sortable .down,
.sortable .none {
    display: inline-block;
    margin-left: 0.5em;
}

.sortable .up {
    transition: all 0.25s;
}

.sortable .down {
    transform: rotate(180deg);
    transition: all 0.25s;
}

.sortable .none {
    color: transparent;
}
